<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reqMethods } from '../../../state/helpers';

/**
 * Lending component
 */
export default {
    components: {
        Layout,
        PageHeader,
        //   Datepicker,
    },
    data() {
        return {
            picked: "",
            daterange: "",
            alertData: [],
            loading: true,
            title: "Alertes reçues",
            items: [
                {
                    text: "Gestion d'alerts",
                    href: "/"
                },
                {
                    text: "Alertes reçues",
                    active: true
                }
            ],
            receivedAlertList: [],
            alertsLus: [],
        };
    },
    mounted() {
        this.getReceivedAlertList()

        const alertsLusFromLocalStorage = localStorage.getItem('alertsSpontLus');

        if (alertsLusFromLocalStorage) {
            this.alertsLus = JSON.parse(alertsLusFromLocalStorage);
        }
    },
    methods: {
        ...reqMethods,
        getReceivedAlertList() {
            const that = this;
            this.loading = true;
            this.getRequest('/alert/alert-spontaneous/')
                .then(
                    function (response) {
                        response.data.forEach(element => {
                            if (element.thematic.name != 'SOS') {
                                that.receivedAlertList.push(element)
                                that.alertData.push(element)
                            }
                        });
                    },
                    function (error) {
                        that.fetchError = error;
                    }
                ).finally(() => {
                    that.loading = false;
                });
        },
        searchFilter(e) {
            const searchStr = e.target.value;
            this.alertData = this.receivedAlertList.filter((alert) => {
                return (
                    alert.title.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.thematic.name.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.sender.name.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.short_code.toLowerCase().includes(searchStr.toLowerCase())
                );
            });
        },
        dateFilter() {
            const dateSearh = this.picked;
            this.alertData = this.receivedAlertList.filter((alert) => {
                return (
                    alert.created_at.split('T')[0].includes(dateSearh)
                );
            });
        },
        afficherDetails(alert) {
            if (!this.alertsLus.includes(alert.short_code)) {
                this.alertsLus.push(alert.short_code);
                localStorage.setItem('alertsSpontLus', JSON.stringify(this.alertsLus));
            }

            this.$router.push({ path: `/receivedAlertDetail/${alert.id}` });
        }
    }
};
</script>
<style>
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh
}
</style>
<style scoped>
.alerte-non-lue {
    background-color: #f2f2f2;
}
</style>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="row justify-content-between">
                        <div class="col-md-5">
                            <div class="form-group mt-3 mb-0">
                                <div class="search-box me-2">
                                    <div class="position-relative">
                                        <input type="text" class="form-control bg-soft bg-info border-0"
                                            placeholder="Rechercher..." @input="searchFilter($event)" />
                                        <i class="bx bx-search-alt search-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-6 justify-content-end">
                            <div class="form-group col-md-4 mt-3 mb-0">
                                <input type="date" placeholder="Date" v-model="picked" class="form-control">
                            </div>
                            <div class="mt-3 col-md-4 mb-0">
                                <button class="btn btn-info" @click="dateFilter">
                                    <i class="bx bx-slider align-middle me-1"></i>
                                    Filtrer
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive mt-3" v-if="!loading">
                        <table class="table table-nowrap table-centered mb-0 align-middle">
                            <thead class="table-light">
                                <tr>
                                    <th>Référence</th>
                                    <th>Auteur</th>
                                    <th>Téléphone</th>
                                    <th scope="col">Thématique</th>
                                    <th scope="col">Pertinence</th>
                                    <th scope="col">Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="alert in alertData" :key=alert.id
                                    :class="{ 'alerte-non-lue': !alertsLus.includes(alert.short_code) }">
                                    <td> {{ alert.short_code }} </td>
                                    <td> {{ alert.sender.name }} </td>
                                    <td> {{ alert.sender.telephone }} </td>
                                    <td> {{ alert.thematic.name }} </td>
                                    <td>
                                        <span class="badge font-size-11 m-1" :class="{
                                            'bg-success': `${alert.pertinence}` == 'PERTINENT',
                                            'bg-danger': `${alert.pertinence}` == 'NON_PERTINENT',
                                            'bg-info': `${alert.pertinence}` == 'DECISIVE',
                                            'bg-primary': `${alert.pertinence}` == 'NORMAL',
                                        }">
                                            {{ alert.pertinence }}
                                        </span>
                                    </td>
                                    <td> {{ alert.created_at.split('T')[0] }} </td>
                                    <td>
                                        <button @click="afficherDetails(alert)" class="btn btn-info btn-sm me-2 w-xs">
                                            Détails
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-if="Object.keys(alertData).length == 0" class="text-center mt-3">
                            <h4>Aucune Alerte Reçue</h4>
                        </div>
                    </div>

                    <div v-else class="loading-container">
                        <div class="text-center">
                            <i class="bx bx-loader bx-spin" style="font-size: 5em;"></i>
                            <h1>Chargement</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
    </Layout>
</template>